<template>
  <div>
    <div v-show="msgSuccess">
      <div class="bg-greenpp border-t-4 border-lime-600 rounded-b text-white px-4 py-3 shadow-md" role="alert">
        <div class="flex">
          <div class="py-1"><svg class="fill-current h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
          <div>
            <p class="font-bold">Success</p>
            <p class="text-sm">{{ msgSuccess }}</p>
          </div>
        </div>
      </div>

    </div>
    <div v-show="msgError">
      <div class="w-full bg-amber-100 px-4 py-3 text-amber-800">
        <div class="mx-auto flex max-w-7xl items-center gap-2">
          <div class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-amber-200 sm:mx-0 sm:size-10">
            <svg class="size-6 text-amber-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
            </svg>
          </div>
          <p class="font-medium">
            {{msgError}}
          </p>
        </div>
      </div>

      </div>

    <div v-show="msgMessage">
      <div class="w-full bg-amber-100 px-4 py-3 text-amber-800">
        <div class="mx-auto flex max-w-7xl items-center gap-2">
          <div class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-amber-200 sm:mx-0 sm:size-10">
            <svg class="size-6 text-amber-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
            </svg>
          </div>
          <p class="font-medium">
            {{msgMessage}}
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    msgSuccess: String,
    msgError: String,
    msgMessage: String
  }
}
</script>
<style>

</style>
